@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Roboto+Condensed:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");

.fadeIn {
  animation: fadeInAnimation 1s;
}

.signatureLabs {
  background: url("../../assets/loveinlabs.svg");
  height: 14px;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 0 50px 0;

  @media (max-width: 745px) {
    margin: 0 0 15px 0;
  }
}

.videoEmbedCustom {
  position: relative;
  z-index: 0;

  @media (max-width: 745px) {
    padding: 30px 0 0 0;
  }

  .warningSound {
    position: absolute;
    right: -200px;
    top: -70px;
    z-index: 10000;
    background: url("../../assets/warningAudio.png");
    height: 120px;
    width: 270px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 1200px) {
      right: -100px;
      top: -70px;
      height: 120px;
      width: 250px;
    }

    @media (max-width: 1300px) {
      right: -50px;
    }

    @media (max-width: 1090px) {
      right: -50px;
      top: -70px;
    }

    @media (max-width: 800px) {
      right: -100px;
      top: -70px;
    }

    @media (max-width: 745px) {
      background: url("../../assets/warningAudioMobile.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      right: 10px;
      top: -14px;
      height: 70px;
      width: 240px;
    }
  }
}

.listCities {
  margin: 10px 0 0 0;

  .cityItem {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
    transition: 100ms;

    .squareBox {
      height: 19px;
      width: 19px;
      border-radius: 2px;
      border: solid #999 2px;
      background-color: #fff;
    }
    span {
      margin-left: 10px;
      color: #444;
      font-size: 16px;
    }
  }
  .cityItem:hover {
    background-color: #f5f5f5;
    transition: 100ms;
  }
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fixedOnTop {
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;

  .size {
    height: 5px;
  }
}

.a-click {
  color: #1e88e5;
  cursor: pointer;
  transition: 500ms;
}
.a-click:hover {
  color: #1976d2;
  transition: 500ms;
}

.gray {
  display: inline;
  color: #777;
  font-weight: 400;
}

.delete-button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 0 4px 0;
}

body {
  background-color: #f5f5f5;
}

.formcustom {
  display: flex;
  .input-group-text-styled {
    width: 100%;
    padding: 10px 15px;
    border-radius: 2px 0 0 2px;
    background-color: #f2f2f2;
    border: solid 1px #ddd;
    color: #656565;
  }
  .form-control-styled {
    width: 100%;
    border-radius: 0 2px 2px 0;
    padding: 10px 12px;
    border: solid 1px #ddd;
  }
}

.formContainer {
  margin: 50px 0 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .custom-select-item {
    width: 100%;
    padding: 11px 12px;
    border: solid 1px #ddd;
    background-color: #fff;
    border-radius: 5px;
    color: #333;
    border: solid 1px #cdcdcd;
  }

  .error {
    border: solid 1px #e57373;
    color: #ef5350;
  }

  .titleHeader {
    width: 850px;
    padding: 0;
    transition: 500ms;
    text-align: center;

    img {
      height: 80px;
      width: 100%;
      margin: 0 0 32px 0;
      user-select: none;
      transition: 200ms;

      @media (max-width: 745px) {
        height: 60px;
      }
    }

    .title {
      font-size: 1.65em;
      margin: 0 0 10px 0;
      font-family: "Roboto Condensed", sans-serif;
      color: #495057;
    }

    .subtitle {
      margin: 0 0 16px 0;
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      color: #128c7e;
      font-weight: 300;
      line-height: 130%;
      padding: 16px;
      border-radius: 6px;
      border: solid 2px rgba($color: #25d366, $alpha: 0.2);
      background-color: rgba($color: #25d366, $alpha: 0.15);
    }
  }

  .formbox {
    background-image: url("../../assets/bgrepeat.png");
    background-position: center;
    background-size: contain;
    background-repeat: repeat;
    transition: 500ms;
    margin: 5px 0 5px 0;
    background-color: #ffffff;
    padding: 20px;
    width: 850px;
    -webkit-box-shadow: 0px 14px 27px 9px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 14px 27px 9px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 14px 27px 9px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    border: solid 1px #dee2e6;

    .groupMyButtons {
      margin: 32px 0 0 0;
      .itemSupport {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 32px 0;
        position: relative;
        h3 {
          font-size: 18px;
          margin: 0 0 18px 0;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #25d366;
          border-radius: 6px;
          padding: 12px 32px;
          text-decoration: none;
          transition: 200ms;
          cursor: pointer;
          user-select: none;
          img {
            height: 22px;
          }
          span {
            color: #fff;
            margin-left: 8px;
          }
        }
        a:hover {
          opacity: 0.9;
          transition: 300ms;
        }
      }

      .downloadBox {
        h3 {
          font-size: 18px;
          margin: 0 0 8px 0;
        }

        display: flex;
        flex-direction: column;
        align-items: center;

        .itemDownload:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
        .itemDownload {
          @media (max-width: 769px) {
            flex-direction: column;
          }

          display: flex;
          align-items: center;
          justify-content: center;
          margin: 16px;
          width: 100%;
          border-bottom: solid 1px #e4e4e4;
          padding-bottom: 32px;

          .logoItem {
            margin: 0 24px 0 0;

            @media (max-width: 769px) {
              margin: 0 0 18px 0;
            }

            img {
              height: auto;
              max-width: 250px;

              @media (max-width: 769px) {
                max-width: 210px;
              }
            }
          }

          .btnBox {
            display: flex;

            a {
              margin: 0 0 0 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 6px;
              padding: 12px 32px;
              text-decoration: none;
              transition: 200ms;
              cursor: pointer;
              user-select: none;
              img {
                height: 22px;
              }
              span {
                color: #fff;
                margin-left: 8px;
              }
            }

            .android_btn {
              background: #85c707;
            }

            .apple_btn {
              background: #231f20;
            }

            a:hover {
              transition: 200ms;
              opacity: 0.9;
            }
          }
        }
      }
    }

    .franchisedRegistration {
      h6 {
        text-align: center;
        padding: 0;
        margin: 0;
        color: #343a40;
        font-size: 1.4em;
        font-family: "Roboto", sans-serif;
      }
    }

    .thanks {
      margin: 20px 0 0 0;
      h5 {
        text-align: center;
        padding: 0;
        margin: 0 0 10px 0;
        color: #333;
        font-size: 1em;
        font-family: "Roboto", sans-serif;
      }

      p {
        margin: 0;
        padding: 0;
        text-align: center;
      }
      .supportMsg {
        text-align: center;
        font-size: 1.05em;
        font-weight: 500;
        color: #868e96;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    label {
      color: #444;
      font-size: 15px;
      font-weight: 500;
    }

    .form-control-style-custom {
      width: 100%;
      padding: 10px 12px;
      border-radius: 5px;
      color: #333;
      border: solid 1px #cdcdcd;
    }

    .error {
      border: solid 1px #e57373;
      color: #ef5350;
    }

    .titleInformation {
      display: flex;
      flex-direction: column;
      padding: 0;
      border-bottom: 1px solid #dfdfdf;
      margin: 0 0 20px 0;

      .itemtitle {
        width: 100%;
        color: #343a40;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 1.21em;
        padding: 0 0 10px 0;
      }
      p {
        font-size: 14px;
        color: #666;
      }
    }
    .continueBox {
      margin: 20px 0 0 0;
      display: flex;
      width: 100%;
      transition: 200ms;
      user-select: none;

      .btn-disable {
        text-align: center;
        width: 100%;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
        background-color: #ddd;
        border: solid 2px #ddd;
        cursor: pointer;
        color: #fff;
        padding: 10px;
        font-size: 0.95em;
        border-radius: 6px;
        transition: 200ms;
      }

      .btn-back {
        text-align: center;
        width: 100%;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
        border: solid 2px #aaa;
        cursor: pointer;
        color: #aaa;
        padding: 10px;
        font-size: 0.95em;
        border-radius: 6px;
        transition: 200ms;
      }

      .btn-continue {
        text-align: center;
        width: 100%;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
        background-color: #51cf66;
        border: solid 2px #51cf66;
        cursor: pointer;
        color: #fff;
        padding: 10px;
        font-size: 0.95em;
        border-radius: 6px;
        transition: 200ms;
      }
      .btn-continue:hover {
        transition: 200ms;
        background-color: #40c057;
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
  .formContainer {
    margin: 30px 20px 15px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .custom-select-item {
      width: 100%;
      padding: 11px 12px;
      border: solid 1px #ddd;
      background-color: #fff;
      border-radius: 5px;
      color: #333;
      border: solid 1px #cdcdcd;
    }

    .titleHeader {
      width: 100%;
      padding: 0;
      transition: 500ms;
      text-align: center;

      .title {
        font-size: 1.3em;
        margin: 0 0 10px 0;
        font-family: "Roboto Condensed", sans-serif;
      }
    }

    .formbox {
      background-image: url("../../assets/bgrepeat.png");
      background-position: center;
      background-size: contain;
      background-repeat: repeat;
      transition: 500ms;
      margin: 5px 0 5px 0;
      background-color: #f5f5f5;
      padding: 20px;
      width: 100%;
      -webkit-box-shadow: 0px 14px 27px 9px rgba(0, 0, 0, 0.09);
      -moz-box-shadow: 0px 14px 27px 9px rgba(0, 0, 0, 0.09);
      box-shadow: 0px 14px 27px 9px rgba(0, 0, 0, 0.09);
      border-radius: 5px;

      label {
        color: #444;
        font-size: 15px;
        font-weight: 500;
      }

      .form-control-style-custom {
        width: 100%;
        padding: 10px 12px;
        border-radius: 5px;
        color: #333;
        border: solid 1px #cdcdcd;
      }

      .titleInformation {
        display: flex;
        flex-direction: column;
        padding: 0;
        border-bottom: 1px solid #dfdfdf;
        margin: 0 0 20px 0;

        .itemtitle {
          width: 100%;
          font-family: "Roboto Condensed", sans-serif;
          font-size: 1.21em;
          padding: 0 0 10px 0;
        }
        p {
          font-size: 14px;
          color: #666;
        }
      }
      .continueBox {
        margin: 20px 0 0 0;
        display: flex;
        width: 100%;
        transition: 200ms;
        user-select: none;

        .btn-disable {
          text-align: center;
          width: 100%;
          font-family: "Roboto Condensed", sans-serif;
          text-transform: uppercase;
          background-color: #ddd;
          border: solid 2px #ddd;
          cursor: pointer;
          color: #fff;
          padding: 10px;
          font-size: 0.95em;
          border-radius: 6px;
          transition: 200ms;
        }

        .btn-back {
          text-align: center;
          width: 100%;
          font-family: "Roboto Condensed", sans-serif;
          text-transform: uppercase;
          border: solid 2px #aaa;
          cursor: pointer;
          color: #aaa;
          padding: 10px;
          font-size: 0.95em;
          border-radius: 6px;
          transition: 200ms;
        }

        .btn-continue {
          text-align: center;
          width: 100%;
          font-family: "Roboto Condensed", sans-serif;
          text-transform: uppercase;
          cursor: pointer;
          color: #fff;
          padding: 10px;
          font-size: 0.95em;
          border-radius: 6px;
          transition: 200ms;
        }
        .btn-continue:hover {
          transition: 200ms;
        }
      }
    }
  }
}
